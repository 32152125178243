<script>
import { DateTime } from 'luxon'

export default {
  name: 'ViewPoap',

  data() {
    return {
      geolocationWatcherId: null,
      coords: null,
      collecting: false,
      loading: false,
      poap: null,
      transaction: null
    }
  },

  computed: {
    collectionDate() {
      return this.transaction?.created?.toLocaleString(DateTime.DATETIME_SHORT)
    },

    distance() {
      if(!this.poap) return null

      return (this.poap.distance - this.poap.geolocationValidationRadius).toFixed(2)
    },

    hasBanner() {
      return !!this.poap?.saylAppBrand?.banner?.url?.all
    },

    hasBrand() {
      return !!this.poap?.saylAppBrand
    },

    hasBrandLogo() {
      return !!this.poap?.saylAppBrand?.logo
    },

    hasDescription() {
      return !!this.poap?.description
    },

    imageUrl() {
      return this.poap?.collectibleImage
    },

    isCollectectible() {
      return this.$route.name === 'sayl-connect_poaps-collectible'
    },

    isPoapCollectible() {
      return this.poap?.isCollectible ?? false
    },
  },

  methods: {
    async collectPoap() {
      try {
        this.collecting = true
        await this.$poaps.collect({ poapLocationId: this.poap.id, long: this.coords.longitude, lat: this.coords.latitude })

        this.$notification({
          title: this.$t('conn3ct-wallet.poap_collected'),
          type: this.$pepper.Intent.SUCCESS
        })

        this.$router.push({ name: 'sayl-connect_poaps-poaps' })
      } catch(e) {
        this.$notification({
          title: e?.orig?.response?.data?.error ? this.$t(`conn3ct-wallet.${ e?.orig?.response?.data?.error }`) : this.$t('conn3ct-wallet.poap_collect_error'),
          type: 'error'
        })
        $console.error(e)
      } finally {
        this.collecting = false
      }
    },

    async init() {
      this.loading = true
      try {
        let res = await this.$poaps.getCollected()

        res = res.find(p => p.id === this.$route.params.id)

        this.transaction = res
        this.poap = res.poapLocation
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    initCollectible() {
      navigator.geolocation.getCurrentPosition(async ({ coords }) => {
        try {
          this.loading = true
          this.coords = coords
          let res = await this.$poaps.discover({ long: coords.longitude, lat: coords.latitude })

          this.poap = res.find(p => p.id === this.$route.params.id)
        } catch(e) {
          $console.error(e)
        } finally {
          this.geolocationWatcherId = setTimeout(this.initCollectible, 10000);
          this.loading = false
        }
      }, $console.error)
    },
  },

  mounted() {
    if(this.isCollectectible) {
      this.initCollectible()
      return
    }

    this.init()
  }
}
</script>

<template>
  <layout-entity
    class="module-poaps poap"
    :key="hasBanner"
    :loading="loading && !poap"
  >
    <template
      v-if="hasBanner"
      v-slot:banner
    >
      <ui-illustration
        :alt="poap.saylAppBrand.banner.alt.all"
        :src="poap.saylAppBrand.banner.url.all"
        :type="poap.saylAppBrand.banner.type"
      />
    </template>

    <ui-card
      class="poap__card"
      :class="{
        '-no-footer': isCollectectible
      }"
      :title="$basil.get(poap, 'name')"
      :subtitle="$basil.get(poap, 'saylAppBrand.name')"
    >
      <template v-slot:illustration>
        <ui-illustration
          :alt="$basil.get(poap, 'name')"
          fit="contain"
          :src="imageUrl"
        />
      </template>

      <template
        v-if="!isCollectectible"
        v-slot:footer
      >
        <p class="poap__card-date">{{ collectionDate }}</p>
      </template>
    </ui-card>

    <ui-panel v-if="isCollectectible">
      <template v-slot:header>
        <h4 class="title">{{ $t(`conn3ct-wallet.poap_collect_status_title_${ isPoapCollectible ? 'collectable' : 'too_far' }`, { distance }) }}</h4>
      </template>

      <div class="body">
        <p v-html="$t(`conn3ct-wallet.poap_collect_status_description_${ isPoapCollectible ? 'collectable' : 'too_far' }`)"></p>
      </div>
    </ui-panel>

    <actions-button
      v-if="isCollectectible"
      @click="collectPoap"
      :appearance="$pepper.Appearance.PRIMARY"
      class="poap__collect-button"
      :disabled="collecting || !isPoapCollectible"
      :loading="collecting"
    >{{ $t('conn3ct-wallet.collect_poap') }}</actions-button>

    <template v-slot:informations>
      <ui-panel v-if="hasDescription">
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.poap_description_title') }}</h4>
        </template>

        <div class="body">
          <p
            v-html="poap.description"
            class="editor"
          ></p>
        </div>
      </ui-panel>

      <ui-panel v-if="hasBrand">
        <template v-slot:header>
          <div class="poap__brand-header">
            <img
              v-if="hasBrandLogo"
              class="poap__brand-logo"
              :src="poap.saylAppBrand.logo.url.all"
              :alt="poap.saylAppBrand.logo.alt.all"
            />

            <h4 class="title">{{ poap.saylAppBrand.name }}</h4>
          </div>
        </template>

        <div
          v-if="poap.saylAppBrand.description"
          class="body"
        >
          <p v-html="poap.saylAppBrand.description"></p>
        </div>
      </ui-panel>

      <ui-panel v-if="!isCollectectible">
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.poap_info_title') }}</h4>
        </template>

        <div class="body">
          <p class="poap__info-row">
            <span class="poap__info-label">{{ $t('conn3ct-wallet.poap_info_token_transaction') }}</span>
            <a
              class="poap__info-value link"
              target="_blank"
              :href="$basil.get(transaction, 'explorerLink')"
            >{{ $basil.get(transaction, 'transactionId') }}</a>
          </p>
        </div>
      </ui-panel>
    </template>
  </layout-entity>
</template>
